import React from 'react'
import { useState } from 'react';
import {Box, Button, Form, Heading, Main, Paragraph, FormField, TextInput} from 'grommet'
import userservice from './services/userservice'

function ForgotPassword(props){
    const [value, setValue] = React.useState({});
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('typing');


    const validate= (email,form)=>{
        if (email.includes("@")==false){
            return "Veuillez renseigner une adresse email"
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setStatus('submitting');
        try {
          await submitForm(value.mail);
          setStatus('success');
        } catch (err) {
          setStatus('typing');
          setError(err);
          console.log(err)
        }
    }

    function submitForm(email) {
        return userservice.sendResetPassword(email)
        .then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
    }

    return (
        <Main pad="large">
        <Heading>Mot de passe oublié</Heading>
        {(status!=="success")&&<Paragraph>nous allons vous envoyer un code de récupération</Paragraph>}
        <Box>
            {(status!=="success")&&
            <Form
            validate="submit"
            defaultValue={value}
            onChange={nextValue => setValue(nextValue)}
            onReset={() => setValue({})}
            onSubmit={handleSubmit}
            >
                <FormField name="mail" htmlFor="text-input-id" label="Adresse email" validate={validate}>
                    <TextInput id="text-input-id" name="mail"/>
                </FormField>
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Envoyer un code de récupération" />
                </Box>
            </Form>
            }
            {(status=="success")&&
                <Box>
                    <Paragraph>Merci ! Si l'utilisateur existe, un email a été envoyé dans sa boîte mail.</Paragraph>
                    <Paragraph>Vous pouvez fermer cette fenêtre et consulter votre boîte mail.</Paragraph>
                </Box>
            }
        </Box>
        </Main>

        
    );
}

export default ForgotPassword