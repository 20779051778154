import React from 'react';

import { Box, CheckBox } from 'grommet';
import { Star } from 'grommet-icons';

function StarRating({grade}){
    const stars = [...Array(5)]
    return (
        <Box direction="row">
            {stars.map((star,i)=><Box><Star key={star} color={i+1<=grade?'orange!':''} /></Box>)}
        </Box>
    );
};

export default StarRating