import React from 'react'
import { Box, Button, Menu, Page, PageContent, PageHeader, Spinner, Text} from 'grommet';
import JoinFamilyForm from './JoinFamilyForm';
import CreateFamilyForm from './CreateFamilyForm';
import Family from './Family'
import { Add, AddCircle, Group } from 'grommet-icons';


class Families extends React.Component{
    constructor(props){
        super()
        this.state={
            families:[],
            isLoading:true,
        }
        this.showAlert=props.setAlert
        this.joinFamily=this.joinFamily.bind(this)
        this.toggleJoinFamilyForm=this.toggleJoinFamilyForm.bind(this)
        this.toggleFamilyCreation=this.toggleFamilyCreation.bind(this)
        this.createFamily=this.createFamily.bind(this)
    }

    componentDidMount(){
        return fetch('/api/families/members/me')
        .then((response) => response.json())
        .then((responseJson) => {
            this.setState({
                isLoading: false,
            })
            if(responseJson.Families){
                this.setState({
                    families : responseJson.Families,
                })
            }
        })
        .catch((error) =>{
            console.error(error);
        });
    }

    toggleJoinFamilyForm(){
        this.setState({showFamilyJoinForm:!this.state.showFamilyJoinForm});
    }

    toggleFamilyCreation(){
        this.setState({showFamilyCreation:!this.state.showFamilyCreation});
    }

    joinFamily(familycode,familyname){
        var user = "me"
        return fetch('/api/families/join/',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                familyname: familyname,
                code:familycode,
                uid:user
            })
        })
        .then((response) => {if (response.ok) {
            response.json()
            .then((responseJson) => {
                this.showAlert("Bienvenue dans la famille","normal");
                this.setState(prevState=>{
                    prevState.families.push(responseJson)
                    console.log(responseJson)
                    return{
                        ...prevState,
                        isLoading: false
                    }
                });
            })
            .catch((error) =>{
                this.showAlert(error.message,"critical")
                console.error(error);
            });
        }
        else{
            throw new Error
        }})
        .catch((error) =>{
            this.showAlert(error.message,"critical")
            console.error(error);
        });
    }

    createFamily(familycode,familyname){
        return fetch('/api/families/',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: familyname,
                code:familycode
            })
        })
        .then((response) => {if (response.ok) {
            response.json()
            .then((responseJson) => {
                this.showAlert("Famille créée","normal")
                console.log(responseJson)
                this.setState(prevState=>{
                    prevState.families.push(responseJson)
                    return{
                        ...prevState,
                        isLoading: false
                    }
                });
            })
            .catch((error) =>{
                this.showAlert(error.message,"critical")
                console.error(error);
            });
        }
        else{
            throw new Error
        }})
        .catch((error) =>{
            this.showAlert(error.message,"critical")
            console.error(error);
        });
    }

    render(){
        const familyarray = this.state.families
        .sort((item1,item2) => item2.Users.length - item1.Users.length)
        .map(item => <Family key={item.uid} name={item.name} code={item.code} id={item.uid} nav={this.nav} users={item.Users} />)
        
        return(
            <Page>
                <PageHeader
                    title="Familles"
                    actions={
                        <Menu
                            icon={<AddCircle size='large' color="brand"/>}
                            items={[
                            {label:<Button 
                                secondary 
                                onClick={this.toggleJoinFamilyForm}
                                label={"Rejoindre une famille existante"}
                                icon={<Group/>}
                            />},
                            {label:<Button 
                                secondary 
                                onClick={this.toggleFamilyCreation}
                                label = "Créer une famille"
                                icon={<Add/>}
                            />}
                            ]}
                        />
                    }
                />
                <PageContent>
                <Box gap='small'>
                    {this.state.isLoading === true &&
                        <Box>
                            <Spinner/>"Chargement des familles..."
                        </Box>
                    }
                    {familyarray}
                </Box>

                {(familyarray.length===0 && this.state.isLoading === false) &&
                <Text>
                    Oh! Il n'y a personne ici <span role="img" aria-label="triste">😿</span>
                </Text>
                }

                <Box>
                    {this.state.showFamilyJoinForm && 
                    <JoinFamilyForm open={this.state.showFamilyJoinForm} toggle={this.toggleJoinFamilyForm} joinFamily={this.joinFamily}></JoinFamilyForm>
                    }
                    {this.state.showFamilyCreation && 
                        <CreateFamilyForm createFamily={this.createFamily} open={this.state.showFamilyCreation} toggle={this.toggleFamilyCreation}></CreateFamilyForm>
                    }
                </Box>



                </PageContent>
            </Page>
            
        )
    };
}

export default Families