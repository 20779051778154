import React from "react";
import Wishist from './WishList'
import { Button } from "grommet";


export default function MyList({setAlert, switchTheme}){
  return(
    <Wishist setAlert={setAlert} user={"me"}/>
  )
}
