import React from 'react'
import Wish2 from './Wish2'
import Wish3 from './Wish3'

import WishForm from './WishForm'
import Errors from './Errors'
import { Modal } from 'shards-react'
import { Anchor, Box, Button, Grid, Page, PageContent, PageHeader, Spinner} from 'grommet';
import { Link } from 'react-router-dom'

class Whishlist extends React.Component{
    constructor(props){
        super()
        this.state={
            wishes:[],
            username:"",
            wishlist:{},
            isLoading:true,
            showModal:false,
            collapse:true,
            title:""
        }
        this.showAlert=props.setAlert
        this.postwish=this.postwish.bind(this)
        this.getWishDetails=this.getWishDetails.bind(this)
        this.putwish=this.putwish.bind(this)
        this.deletewish=this.deletewish.bind(this)
        this.toggle=this.toggle.bind(this)
        this.self=props.user==="me"
    }
    
    componentDidMount(){
        var user = this.props.user
        fetch('/api/users/'+ user + '/wishlists')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    wishes: responseJson[0].Wishes,
                    wishlist: responseJson[0],
                    username: responseJson[0].User.name,
                    title: this.self? "Ma liste de cadeaux":"Liste de "+ responseJson[0].User.name
                }, function(){});
            })
            .catch((error) =>{
                console.error(error);
            });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    getWishDetails = async(url) =>{
        const q = "/api/wishes/findURL"
        const details = await fetch(q,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               url: url
            })
        })
        .then(response => response.json())
        .catch((error) =>{
            console.error(error);
        })

        return details
    }

    postwish(newwish){
        const q = '/api/wishlists/'+this.state.wishlist.uid+'/wishes'
        fetch(q, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newwish)
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau ajouté","normal")
                console.log(data)
                return prevState.wishes.push(data) 
            })
        })
        .catch(err=>
            this.showAlert(err.message,"normal")
        )
    }

    putwish(newwish,wishId){
        fetch('/api/wishes/'+wishId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newwish)
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau modifié","normal")
                let newWishes = prevState.wishes
                for (var i = 0; i < newWishes.length; ++i) {
                    if (newWishes[i]['uid'] === wishId) {
                        newWishes[i] = data;
                    }
                }
                return {
                    ...prevState,
                    wishes : newWishes,
                    isLoading:false
                }
            })
        })
        .catch(err=>
            this.showAlert(err.message,"critical")
        )
    }

    deletewish(wishId){
        this.setState({isLoading: true})
        fetch('/api/wishes/'+wishId, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response
        })
        .then(response=>response.json())
        .then(data => {
            this.setState(prevState=>{ 
                this.showAlert("Cadeau supprimé","normal")
                let newWishes = prevState.wishes
                for (var i = 0; i < newWishes.length; i++) {
                    if (newWishes[i]['uid'] === wishId) {
                        newWishes.splice(i,1)
                    }
                }
                return {
                    ...prevState,
                    wishes:newWishes,
                    isLoading:false
                }
            })
        })
        .catch(err=>
            this.showAlert(err.message,"critical")
        )
    }

    render(){
        const wisharray = this.state.wishes
        .sort((item1,item2) => item2.importance - item1.importance || item1.name.localeCompare(item2.name))
        .map(item => <Wish3 
                        getWishDetails={this.getWishDetails} 
                        putwish={this.putwish} 
                        deletewish={this.deletewish} 
                        key={item.uid} 
                        name={item.name} 
                        importance={item.importance} 
                        isSecret={item.isSecret} 
                        isPurchased={item.isPurchased} 
                        self={this.self} 
                        comment={item.comment} 
                        img={item.img} 
                        handleChange={this.handleChange} 
                        createdAt ={item.createdAt} 
                        url={item.url} 
                        id={item.uid}
                    />)
        
        return(
            <Page>
                <PageHeader
                    actions={<Button 
                                primary
                                disabled={!this.state.collapse} 
                                onClick={this.toggle}
                                label={`${this.self===false ? '👻':""} Ajouter un cadeau ${this.self===false ? 'secret':""}`}
                                />
                            }
                    parent={<Link to="/app/families/"><Anchor label="Familles" preventScrollReset={true}/></Link>}
                    title={this.state.title}
                />
                        
                        
                
                <Modal backdropClassName="modalBackdrop" open={!this.state.collapse}>
                    <WishForm getWishDetails={this.getWishDetails} empty={this.state.emptyform} toggle={this.toggle} handleClick={this.postwish} self={this.self===true}></WishForm>
                </Modal>
                
                <PageContent>
                    {this.state.isLoading === true &&
                        <div><Spinner/><span><br/>Chargement des cadeaux...</span></div>
                    }
                    <Box>
                        <Grid fill="horizontal" gap="small" columns={{size:"medium"}}>{wisharray}</Grid>
                        {(wisharray.length===0 && this.state.isLoading === false) &&
                        <Errors></Errors>
                    }
                    </Box>
                    
                </PageContent>
            </Page>           
        )
    }
}

export default Whishlist