import React, {useState} from 'react'
import Appalert from './Appalert'
import { FormInput } from 'shards-react';
import {Link} from "react-router-dom"
import userservice from './services/userservice';
import LoginBg from './login-bg2.svg'
import { UserContext } from './services/user-context';
import { redirect, ScrollRestoration} from 'react-router-dom';
import { Anchor, Box, Button, Form, FormField, Header, Layer, Page, PageContent, PageHeader, TextInput} from 'grommet';
import {AppAlert} from './Appalert'

export default function LoginForm(){
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openRegister, setOpenRegister] = useState('');

    const [alert, setAlert] = useState({
        message: "",
        status: "normal"
      });
    
    const updateAlert = (message, status)=>{
    setAlert(previousState => {
        return { ...previousState, message: message, status:status }
    });
    }

    const signin =(login,password,setUser)=>{
        console.log('click')
        userservice.signin(login,password)
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            if(data.message){
                updateAlert(data.message,"critical")
                setUser("")
                sessionStorage.removeItem('user');
            }
            else{
                //console.log('login ok')
                sessionStorage.setItem('user', data.userId);
                setUser(data.userId)
                redirect("/")
            }  
        })
        .catch(err=>{
            //this.props.refreshuser(false)
            updateAlert(err.message,"critical")
            //setUser(true)
            sessionStorage.removeItem('user');
        })
    }
    
    const register=(username,password,email )=>{
    const q = "/api/register"
    return fetch(q,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "name":username,
            "password":password,
            "email":email
        })
    })
    .then(response => response.json())
    .then((data) => {
        console.log(data)
        if(data.message){
            //this.showAlert(data.message,"critical")
            updateAlert(data.message,"critical")
            //this.props.refreshuser(false)
        }
        else{
            updateAlert("Utilisateur créé","critical")
            this.setState({register:false})
        }  
    })
    .catch(err=>{
        console.log(err)
        //this.props.refreshuser(false)
        updateAlert(err,"critical")
    }) 
    }

    return(<>
    <ScrollRestoration/>
    <UserContext.Consumer>
        {({user, updateUser}) => (
        <Layer direction="row" full animation="fadeIn" background={{ image: `url(${LoginBg})`, color: 'light-2' }}>
            <Box alignSelf='center'>
                <Page position="center" justify="center" alignSelf="center" alignContent="center" pad="large" background={{color:"white", opacity:0.7}}>
                    <PageHeader title={openRegister ? "Nouvel utilisateur":"Connexion"}/>
                    <PageContent>
                            <Form>
                            <Box>
                                {openRegister &&
                                    <div>
                                        <label htmlFor="#username">Adresse email</label>
                                        <TextInput id="#email" placeholder="Adresse email" onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                }
                            </Box>
                            <Box>
                                <label htmlFor="#username">Login</label>
                                <TextInput id="#username" placeholder="Nom d'utilisateur ou adresse email" onChange={(event) => setLogin(event.target.value)} />
                                <label htmlFor="#password">Mot de passe</label>
                                <TextInput type="password" id="#password" placeholder="Mot de passe" onChange={(event) => setPassword(event.target.value)} />
                            </Box>
                            <Box direction="row" pad={{vertical:"medium"}} gap="small">
                                {openRegister ?(
                                    <Button label={"S'enregister"} onClick={()=>register(login,password,email)}></Button>
                                ):(
                                    <Button primary label={"Se connecter"} onClick={()=>signin(login,password, updateUser)}></Button>
                                )}
                                <Button label={openRegister ? "Retour":"Créer un profil"} onClick={() => setOpenRegister(!openRegister)}></Button>
                            </Box>
                            <Anchor target="_blank" rel="noopener noreferrer" href="/forgotpassword">Mot de passe oublié ?</Anchor>
                            </Form>

                    </PageContent>
                </Page>
            </Box>
        </Layer>
        )}

    </UserContext.Consumer>
    </>
    );
    
}