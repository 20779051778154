import React from "react";
import { Page } from "grommet";
import { Outlet } from "react-router-dom";


export default function Main() {
    return(
        <Page full={true} pad='small'>
            <Outlet/>
        </Page>
    )
};

