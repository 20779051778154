import React from 'react'
import User from './User'
import {Modal, ModalBody, ModalHeader} from "shards-react";
import { Box, Button, Card, CardBody, CardHeader, Grid, Heading, Text} from 'grommet';
import { UserAdd } from 'grommet-icons';
import JoinFamilyForm from './JoinFamilyForm';
import CreateFamilyForm from './CreateFamilyForm';

class Family extends React.Component{
    constructor(props){
        super()
        this.state={
            users:props.users,
            familyName:props.name,
            familyCode:props.code,
            showFamilyJoinForm:false,
            showFamilyCode:false,
            showFamilyCreation:false
        }
        this.nav=props.nav
        this.toggleFamilyCode=this.toggleFamilyCode.bind(this)
    }

    toggleFamilyCode(){
        this.setState({showFamilyCode:!this.state.showFamilyCode});
    }

    render(){
        const userarray = this.state.users
        .sort((item1,item2) => {
            if(item1.name.toLowerCase() < item2.name.toLowerCase()) return -1;
            if(item1.name.toLowerCase() > item2.name.toLowerCase()) return 1;
        })
        .map(item => <User key={item.uid} name={item.name} id={item.uid} status={item.status} img={item.img}/>)
        
        return(
            <Box>
                <Card>
                    <CardHeader pad="small">
                        <Heading size="small" margin="small">{this.state.familyName}</Heading>
                        <Box>
                            <Button secondary
                                onClick={this.toggleFamilyCode}
                                icon={<UserAdd/>}
                                label={"Inviter des participants"}
                                size="small"
                            />
                        </Box>
                    </CardHeader>
                    <CardBody pad="medium">
                        <Box direction='row-responsive' gap="xsmall">{userarray}</Box>
                        <Box>
                            {(userarray.length===0) &&
                            <Text>
                                Oh! Il n'y a personne ici <span role="img" aria-label="triste">😿</span>
                            </Text>
                            }
                        </Box>
                        {this.state.showFamilyJoinForm && 
                            <JoinFamilyForm open={this.state.showFamilyJoinForm} toggle={this.toggleJoinFamilyForm} joinFamily={this.joinFamily}></JoinFamilyForm>
                        }
                        {this.state.showFamilyCreation && 
                            <CreateFamilyForm createFamily={this.createFamily} open={this.state.showFamilyCreation} toggle={this.toggleFamilyCreation}></CreateFamilyForm>
                        }
                        <Modal backdropClassName="modalBackdrop" open={this.state.showFamilyCode} toggle={this.toggleFamilyCode}>
                            <ModalHeader>Inviter des participants</ModalHeader>
                            <ModalBody>
                                <Box>
                                    <div className="p-3 mt-3 border rounded">
                                        <h5>Les informations à communiquer</h5>
                                        <p>Pour ajouter quelqu'un à votre cercle familial, transmettez lui les informations suivantes :</p>
                                        <p>Nom de famille : <b>{this.state.familyName}</b></p>
                                        <p>Code de famille : <b>{this.state.familyCode}</b></p>
                                    </div>
                                </Box>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                    
                </Card>
                    
            </Box>
            
        )
    };
}

export default Family