import React from 'react'
import { 
    CardImgOverlay,
    Modal
 } from "shards-react";

import {Anchor, Box, Button, Card, CardBody, CardFooter, CardHeader, CheckBox, Footer, Header, Heading, Image, Layer, Paragraph, Stack, Tag, Text} from 'grommet';
import {Edit,Link} from "grommet-icons";
import StarRating from './components/StarRating';
import WishForm from './WishForm';

export default function Wish3(props){
    const [isLoading, setIsLoading] = React.useState();
    const [isEdit, setIsEdit] = React.useState();
    const [purchased, setPurchased] = React.useState(props.isPurchased);
    const date = new Date(props.createdAt);
    const ref = React.useRef();

    function togglePurchased(){
        props.putwish(
            {
                "isPurchased": !purchased
            },props.id)
        setPurchased(!purchased);
    }

    function toggleEdit(){
        setIsEdit(!isEdit);
    }

    return (
                <Stack>
                <Box width={{min: "50%"}} elevation="small" height="medium" gap="small" direction='row' border={{ color: 'grey-2', size: 'xsmall' }}>      
                    {props.img && 
                        <Box flex={{ grow: 0, shrink: 2}} width="medium">
                            <Image fill="vertical" elevation="medium" fit="cover" src={props.img}/>
                        </Box>} 
                        <Box direction = "column" flex={{ grow: 1, shrink: 1 }} pad="medium"> 
                            <Box wrap="true" direction = "row">
                                <Heading size="small">{props.name}</Heading>                                                         
                            </Box> 
                            <Box wrap="true" direction = "row">
                                <StarRating grade={props.importance}/>
                                <Tag height="30px" size="xsmall" name="Ajouté le" value={new Intl.DateTimeFormat('fr-fr').format(date)}/>
                                {props.isSecret && 
                                    <Tag height="30px" size="xsmall" alignSelf="end" value={<> <span role="img" aria-label="secret">👻</span>secret </>}/>
                                }
                            </Box>
                            <Box pad={{ top: 'medium' }} fill="true" direction = "row">
                                <Box overflow={"scroll"}>
                                    <Paragraph >{props.comment}</Paragraph>
                                </Box>
                            </Box>   
                            
                                   
                            <Box direction="row" alignSelf="end" pad="small"> 
                                <Box direction="row" wrap="true">
                                    {props.url && 
                                    <Button secondary>
                                        <Link size="small"/> <Anchor href={props.url} label="Voir le site" rel="noopener noreferrer" target="_blank"/> 
                                    </Button>
                                    }   
                                    <Box>
                                        {(props.isSecret || props.self) && 
                                            <Button  
                                                secondary
                                                onClick={()=>setIsEdit(!isEdit)}
                                                label="Modifier"
                                            />
                                        }
                                    </Box>
                                    <Box>
                                        {props.self===false &&
                                        <CheckBox
                                            toggle
                                            checked={purchased}
                                            onChange={()=>togglePurchased()}
                                            label="Acheté"
                                        />
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    {isEdit && 
                    <Layer>
                        <WishForm 
                            editForm 
                            deletewish={props.deletewish} 
                            putwish={props.putwish} 
                            getWishDetails={props.getWishDetails} 
                            toggle={toggleEdit} 
                            wish={props} 
                            open={isEdit}>
                        </WishForm>
                    </Layer>
                    }
                </Box>
                    {(props.self===false && props.isPurchased) &&
                        <Box background = "#000000A0" width={{min: "50%"}} elevation="small" height="medium" pad="medium" direction='column' border={{ color: 'grey-2', size: 'xsmall' }}>
                            <Heading alignSelf='center' textAlign='center'>Cadeau acheté ! 🎉</Heading>
                            <CheckBox
                                            toggle
                                            checked={purchased}
                                            onChange={()=>togglePurchased()}
                                            label="Acheté"
                                        />
                        </Box>
                    }
                </Stack>

    );
}
