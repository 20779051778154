import React from 'react'
import { 
    CardImgOverlay,
    Modal
 } from "shards-react";

import {Anchor, Box, Button, Card, CardBody, CardFooter, CardHeader, CheckBox, Footer, Header, Heading, Image, Layer, Paragraph, Stack, Tag, Text} from 'grommet';
import {Edit,Link} from "grommet-icons";
import StarRating from './components/StarRating';
import './App.css';
import WishForm from './WishForm';


class Wish2 extends React.Component{
    constructor(props){
        super()
        this.state={
            isLoading:true,
            collapse:true,
            edit:false, 
        }
        this.toggle=this.toggle.bind(this)
        this.editWish=this.editWish.bind(this)
        this.togglePurchased=this.togglePurchased.bind(this)
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    editWish() {
        this.setState({ edit: !this.state.edit });
    }

    togglePurchased(){
        this.props.putwish(
        {
            "isPurchased": !this.props.isPurchased
        },this.props.id)
    }

    render(){
        const date = new Date(this.props.createdAt);
        return (
            <Box elevation="small" height="medium" direction='row' border={{ color: 'grey-2', size: 'xsmall' }}>      
                {this.props.img && 
                <Box fill="vertical" width="large">
                    <Image fill="vertical" elevation="medium" fit="cover" src={this.props.img}/>
                </Box>} 

                <Box pad="medium" direction = "column" fill="horizontal"> 
                    <Heading size="small">
                        {this.props.name}
                    </Heading>     
                    <Box height="small" direction="row-responsive" gap="medium">
                        <StarRating grade={this.props.importance}/>
                        <Tag height="30px" size="xsmall" name="Ajouté le" value={new Intl.DateTimeFormat('fr-fr').format(date)}/>
                        {this.props.isSecret && 
                            <Tag height="30px" size="xsmall" value={<> <span role="img" aria-label="secret">👻</span>secret </>}/>
                        }
                    </Box>  
                    <Box overflow={"scroll"}>
                        <Paragraph>{this.props.comment}</Paragraph>
                    </Box>      
                    {this.props.isPurchased &&
                        <Layer>
                            yo
                        </Layer> 
                    }              
                    <Footer pad="none">

                        <Box direction="row" gap="small">
                            <Box>
                                {(this.props.isSecret || this.props.self) && 
                                    <Button  
                                        secondary
                                        onClick={this.editWish}
                                        label="Modifier"
                                    />
                                }
                            </Box>
                            <Box>
                                {this.props.self===false &&
                                <CheckBox
                                    toggle
                                    checked={this.props.isPurchased}
                                    onChange={()=>this.togglePurchased()}
                                    label="Acheté"
                                />
                                }
                            </Box>
                            {this.props.url && 
                            <Button secondary>
                                <Link size="small"/> <Anchor href={this.props.url} label="Voir le site" rel="noopener noreferrer" target="_blank"/> 
                            </Button>
                            }
                        </Box>
                    </Footer>
                </Box>
                {this.state.edit && 
                <Layer>
                    <WishForm editForm deletewish={this.props.deletewish} putwish={this.props.putwish} getWishDetails={this.props.getWishDetails} toggle={this.editWish} wish={this.props} open={this.state.edit}></WishForm>
                </Layer>
                }
            </Box> 
        )
    } 
}
export default Wish2