import React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, Link } from 'react-router-dom';
import {Avatar, Card, CardBody, CardHeader, Header, Button, CardFooter} from "grommet"
import styled from 'styled-components';

const StyledLink = styled(Link)`
    color: brand !important;
    text-decoration: none;
    &:hover {
        color: text!;
        text-decoration: none;
        }
  `;

function User(props){
    return (
        <StyledLink to={"/app/users/"+props.id} preventScrollReset={true}>
            <Card width="small">
                <CardHeader pad="small"><Header>{props.name}</Header></CardHeader>
                <CardBody align="center" pad="small">
                    <Avatar size="xlarge" src={props.img}/>
                </CardBody> 
            </Card>    
        </StyledLink>        
    )
}

export default User