import React from 'react'
import { useState } from 'react';
import {Box, Button, Form, Heading, Main, Paragraph, FormField, TextInput} from 'grommet'
import { Hide, View } from 'grommet-icons';
import userservice from './services/userservice'
import { useLoaderData, Link } from "react-router-dom";

export async function loader({ params }) {
    return (params);
}

function ChangePassword(props){
    const [value, setValue] = React.useState({});
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('typing');
    const [reveal, setReveal] = React.useState(false);
    
    const {userId,hash} = useLoaderData();

    const validate=(field,form)=>{
        if (field!==form.pass){
            return "les mots de passe doivent être identiques"
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        setStatus('submitting');
        try {
          await submitForm(value.pass);
          setStatus('success');
        } catch (err) {
          setStatus('typing');
          setError(err);
        }
    }

    function submitForm(pass){
        return userservice.changePasswordFromHash(userId,hash,pass)
        .then(response => {
            if(!response.ok){
                throw Error("Le lien de récupération n'était pas valide");
            }
            return response
        })
        .catch((err)=>{
            throw Error(err.message);
        })
    }
    
    return (
        <Main pad="large">
            <Heading>Votre nouveau mot de passe</Heading>
            <Box>
                {(status!=="success" && error==null)&&
                <Form
                validate="submit"
                defaultValue={value}
                onChange={nextValue => setValue(nextValue)}
                onSubmit={handleSubmit}
                >
                <FormField name="pass" htmlFor="text-input-id" label="Mot de passe">
                    <Box
                    width="medium"
                    direction="row"
                    margin="large"
                    align="center"
                    round="small"
                    >
                        <TextInput id="text-input-id" name="pass" type={reveal ? 'text' : 'password'}/>
                        <Button
                           icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                           onClick={() => setReveal(!reveal)}
                         />
                    </Box>
                        
                            
                </FormField>
                    
                
                <FormField name="passconfirm" htmlFor="text-input-id" label="Confirmer le mot de passe" validate={validate}>
                    <Box
                        width="medium"
                        direction="row"
                        margin="large"
                        align="center"
                        round="small"
                    >
                        <TextInput id="text-input-id" name="passconfirm" type={reveal ? 'text' : 'password'}/>
                        <Button
                            icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                            onClick={() => setReveal(!reveal)}
                        />
                    </Box>
                </FormField>
                
                <Box direction="row" gap="medium">
                    <Button type="submit" primary label="Confirmer" />
                </Box>
                </Form>
                }
                {(status==="success")&&
                <Box>
                    <Paragraph>Votre mot de passe a bien été modifié</Paragraph>
                    <Link to="/"> Retour à la connexion</Link>
                </Box>
                }
                {(error!==null)&&
                <Box>
                    <Paragraph>Erreur : {error.message}</Paragraph>
                    <Link to="/"> Retour à la connexion</Link>
                </Box>
                }
            </Box>
        </Main>
    );
}

export default ChangePassword