import React, {useState} from 'react';
import {Grommet, grommet} from "grommet";
import {createBrowserRouter, RouterProvider, redirect, ScrollRestoration } from 'react-router-dom';
import Home from './Home'
import ForgotPassword from './ForgotPassword'
import ChangePassword, {loader as changePasswordLoader} from './ChangePassword'
import FamilyWishList from './FamilyWhishList';
import MyList from './MyList';
import Families from './Families';
import ErrorPage from './ErrorPage';
import LoginForm from './LoginForm';
import Appalert from "./Appalert";
import { UserContext } from './services/user-context';
import { deepMerge } from "grommet/utils";
import { thewisher } from './thewisher-theme';


export default function App(){
  const [user, setUser] = useState(sessionStorage.getItem('user'))
  
  const updateUser=(data)=>{
    console.log('set user in app state')
    sessionStorage.setItem('user',data)
    setUser(data)
  }

  const [alert, setAlert] = useState({
    message: "",
    status: "normal"
  });
  const [darkMode, setDarkMode] = React.useState(false);
  const ThemeContext = React.createContext('light');

  const updateAlert = (message, status)=>{
    setAlert(previousState => {
      return { ...previousState, message: message, status:status }
    });
  }

  const switchTheme =()=>{
    setDarkMode(!darkMode)
  }

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      loader:async () => {
        console.log("/ loader, user : ", user)
        if (!user){
          console.log("user not detected, redirecting...")
          return redirect("/login")
        }
        else{
          console.log(" session detected")
          return redirect("/app/my-list")
        }
      }
    },
    {
      path: "/app",
      element: <Home switchTheme={switchTheme} darkMode={darkMode} updateUser={updateUser}/>,
      errorElement: <ErrorPage />,
      loader:async () => {
        console.log("app loader, user : ", user)
        if (!user){
          console.log("user not detected, redirecting...")
          return redirect("/login")
        }
      },
      children: [
        {
          path: "/app/my-list",
          element: <MyList switchTheme={switchTheme} setAlert={updateAlert}/>
        },
        {
          path: "/app/users/:id",
          element: <FamilyWishList setAlert={updateAlert}/>
        },
        
        {
          path: "/app/families",
          exact: true,
          element: <Families setAlert={updateAlert}/>
        }
      ],
    },
    {
      path: "/login",
      element: <LoginForm setAlert={updateAlert}/>,
      loader: async () => {
        console.log("login loader")
        //setUser(window.cookie)
        if (user){
          console.log("user detected, redirecting...")
          return redirect("/")
        }
      },
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword/>
    },
    {
      path: "/reset/:userId/:hash",
      element: <ChangePassword />,
      loader: changePasswordLoader,
    },
  ]);

  return(
    <ThemeContext.Provider value="dark">
      <UserContext.Provider value={{user, updateUser}}>
        <Grommet full theme={deepMerge(grommet,thewisher)} themeMode={darkMode ? "dark" : "light"} >
        {alert.message&&
          <Appalert setAlert={updateAlert} alert={alert}></Appalert>
        }
        <RouterProvider router={router} />
      </Grommet>
      </UserContext.Provider>
    </ThemeContext.Provider>
  )
}
